import * as React from 'react';
import moment from 'moment';
import alertPage from 'src/services/alertPage';
import { useSelector } from 'react-redux';

export const LicenseContext = React.createContext({});

const DEFAULT_SUPPORT = {
  lead: {
    name: '',
    analyst: ''
  },
  analyst: {
    name: '',
    email: ''
  }
};

export const LicenseContextProvider = ({ children }) => {
  const user = useSelector(state => state.account.user);
  const [licenseLoading, setLicenseLoading] = React.useState(false);
  const [licenseStepperData, setLicenseStepperData] = React.useState([]);
  const [supportData, setSupportData] = React.useState(DEFAULT_SUPPORT);
  const [taskSearchOptions, setTaskSearchOptions] = React.useState([]);
  const [notesData, setNotesData] = React.useState([]);

  // FETCH LICENSE STEPPER CARD DATA
  const fetchLicenseSummary = async () => {
    try {
      setLicenseLoading(true);
      const { data, searchOptions } = await alertPage.getLicenseSummary(user.id);
      const licenseStepperData = data.map(data => {
        const anticipatedDaysCount = moment().diff(
          moment(data.start_date ? data.start_date : data.Date_of_Creation),
          'days'
        );
        return {
          licenseID: data.id,
          licenseName: data.certificate_name,
          state: data.state,
          state_abbr: data.state_abbr,
          validity: data.validity,
          anticipatedDaysCount,
          overdueCount: data.overdueTasksCount,
          timelyCount: data.timelyTasksCount,
        };
      });
      setLicenseStepperData(licenseStepperData);
      setTaskSearchOptions(searchOptions);
    } catch (err) {
      console.error('Error fetching License Stepper Data: ', err);
      setLicenseLoading(true);
      setLicenseStepperData([]);
      setTaskSearchOptions([]);
    } finally {
      setLicenseLoading(false);
    }
  };

  const fetchUserNotes = async () => {
    try {
      const { data: licenseNotes } = await alertPage.getUserLicenseNote(user.id);
      setNotesData(licenseNotes);
    } catch (err) {
      setNotesData([]);
    }
  };

  // FETCH USER SUPPORT DATA - ANALYST, LEAD ANALYST
  const fetchSupportData = async () => {
    try {
      const { data } = await alertPage.getAnalyst(user.id);
      setSupportData(data);
    } catch (err) {
      console.error('Error fetching Support data: ', err);
      setSupportData({});
    }
  };

  React.useEffect(() => {
    fetchLicenseSummary();
    fetchUserNotes();
    fetchSupportData();
  }, []);

  const defaultContext = {
    licenseLoading,
    licenseStepperData,
    supportData,
    taskSearchOptions,
    notesData
  };

  return (
    <LicenseContext.Provider value={defaultContext}>
      {children}
    </LicenseContext.Provider>
  );
};

export const useLicenceContext = () => {
  return React.useContext(LicenseContext);
}