export const countryList = [
  {name: 'Afghanistan', code: 'AF'},
  {name: 'Albania', code: 'AL'},
  {name: 'Algeria', code: 'DZ'},
  {name: 'American Samoa', code: 'AS'},
  {name: 'AndorrA', code: 'AD'},
  {name: 'Angola', code: 'AO'},
  {name: 'Anguilla', code: 'AI'},
  {name: 'Antarctica', code: 'AQ'},
  {name: 'Antigua and Barbuda', code: 'AG'},
  {name: 'Argentina', code: 'AR'},
  {name: 'Armenia', code: 'AM'},
  {name: 'Aruba', code: 'AW'},
  {name: 'Australia', code: 'AU'},
  {name: 'Austria', code: 'AT'},
  {name: 'Azerbaijan', code: 'AZ'},
  {name: 'Bahamas', code: 'BS'},
  {name: 'Bahrain', code: 'BH'},
  {name: 'Bangladesh', code: 'BD'},
  {name: 'Barbados', code: 'BB'},
  {name: 'Belarus', code: 'BY'},
  {name: 'Belgium', code: 'BE'},
  {name: 'Belize', code: 'BZ'},
  {name: 'Benin', code: 'BJ'},
  {name: 'Bermuda', code: 'BM'},
  {name: 'Bhutan', code: 'BT'},
  {name: 'Bolivia', code: 'BO'},
  {name: 'Bosnia and Herzegovina', code: 'BA'},
  {name: 'Botswana', code: 'BW'},
  {name: 'Bouvet Island', code: 'BV'},
  {name: 'Brazil', code: 'BR'},
  {name: 'British Indian Ocean Territory', code: 'IO'},
  {name: 'Bulgaria', code: 'BG'},
  {name: 'Burkina Faso', code: 'BF'},
  {name: 'Burundi', code: 'BI'},
  {name: 'Cambodia', code: 'KH'},
  {name: 'Cameroon', code: 'CM'},
  {name: 'Canada', code: 'CA'},
  {name: 'Cape Verde', code: 'CV'},
  {name: 'Cayman Islands', code: 'KY'},
  {name: 'Central African Republic', code: 'CF'},
  {name: 'Chad', code: 'TD'},
  {name: 'Chile', code: 'CL'},
  {name: 'China', code: 'CN'},
  {name: 'Christmas Island', code: 'CX'},
  {name: 'Cocos (Keeling) Islands', code: 'CC'},
  {name: 'Colombia', code: 'CO'},
  {name: 'Comoros', code: 'KM'},
  {name: 'Congo (Brazzaville)', code: 'CG'},
  {name: 'Congo (Kinshasa)', code: 'CD'},
  {name: 'Cook Islands', code: 'CK'},
  {name: 'Costa Rica', code: 'CR'},
  {name: 'Cote D\'Ivoire', code: 'CI'},
  {name: 'Croatia', code: 'HR'},
  {name: 'Cuba', code: 'CU'},
  {name: 'Cyprus', code: 'CY'},
  {name: 'Czech Republic', code: 'CZ'},
  {name: 'Denmark', code: 'DK'},
  {name: 'Djibouti', code: 'DJ'},
  {name: 'Dominica', code: 'DM'},
  {name: 'Dominican Republic', code: 'DO'},
  {name: 'Ecuador', code: 'EC'},
  {name: 'Egypt', code: 'EG'},
  {name: 'El Salvador', code: 'SV'},
  {name: 'Equatorial Guinea', code: 'GQ'},
  {name: 'Eritrea', code: 'ER'},
  {name: 'Estonia', code: 'EE'},
  {name: 'Ethiopia', code: 'ET'},
  {name: 'Falkland Islands', code: 'FK'},
  {name: 'Faroe Islands', code: 'FO'},
  {name: 'Fiji', code: 'FJ'},
  {name: 'Finland', code: 'FI'},
  {name: 'France', code: 'FR'},
  {name: 'French Guiana', code: 'GF'},
  {name: 'French Polynesia', code: 'PF'},
  {name: 'French Southern Territories', code: 'TF'},
  {name: 'Gabon', code: 'GA'},
  {name: 'Gambia', code: 'GM'},
  {name: 'Georgia', code: 'GE'},
  {name: 'Germany', code: 'DE'},
  {name: 'Ghana', code: 'GH'},
  {name: 'Gibraltar', code: 'GI'},
  {name: 'Greece', code: 'GR'},
  {name: 'Greenland', code: 'GL'},
  {name: 'Grenada', code: 'GD'},
  {name: 'Guadeloupe', code: 'GP'},
  {name: 'Guam', code: 'GU'},
  {name: 'Guatemala', code: 'GT'},
  {name: 'Guernsey', code: 'GG'},
  {name: 'Guinea', code: 'GN'},
  {name: 'Guinea-Bissau', code: 'GW'},
  {name: 'Guyana', code: 'GY'},
  {name: 'Haiti', code: 'HT'},
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
  {name: 'Holy See (Vatican City State)', code: 'VA'},
  {name: 'Honduras', code: 'HN'},
  {name: 'Hong Kong', code: 'HK'},
  {name: 'Hungary', code: 'HU'},
  {name: 'Iceland', code: 'IS'},
  {name: 'India', code: 'IN'},
  {name: 'Indonesia', code: 'ID'},
  {name: 'Iran', code: 'IR'},
  {name: 'Iraq', code: 'IQ'},
  {name: 'Ireland', code: 'IE'},
  {name: 'Isle of Man', code: 'IM'},
  {name: 'Israel', code: 'IL'},
  {name: 'Italy', code: 'IT'},
  {name: 'Jamaica', code: 'JM'},
  {name: 'Japan', code: 'JP'},
  {name: 'Jersey', code: 'JE'},
  {name: 'Jordan', code: 'JO'},
  {name: 'Kazakhstan', code: 'KZ'},
  {name: 'Kenya', code: 'KE'},
  {name: 'Kiribati', code: 'KI'},
  {name: 'North Korea', code: 'KP'},
  {name: 'South Korea', code: 'KR'},
  {name: 'Kuwait', code: 'KW'},
  {name: 'Kyrgyzstan', code: 'KG'},
  {name: 'Laos', code: 'LA'},
  {name: 'Latvia', code: 'LV'},
  {name: 'Lebanon', code: 'LB'},
  {name: 'Lesotho', code: 'LS'},
  {name: 'Liberia', code: 'LR'},
  {name: 'Libyan', code: 'LY'},
  {name: 'Liechtenstein', code: 'LI'},
  {name: 'Lithuania', code: 'LT'},
  {name: 'Luxembourg', code: 'LU'},
  {name: 'Macao', code: 'MO'},
  {name: 'Macedonia', code: 'MK'},
  {name: 'Madagascar', code: 'MG'},
  {name: 'Malawi', code: 'MW'},
  {name: 'Malaysia', code: 'MY'},
  {name: 'Maldives', code: 'MV'},
  {name: 'Mali', code: 'ML'},
  {name: 'Malta', code: 'MT'},
  {name: 'Marshall Islands', code: 'MH'},
  {name: 'Martinique', code: 'MQ'},
  {name: 'Mauritania', code: 'MR'},
  {name: 'Mauritius', code: 'MU'},
  {name: 'Mayotte', code: 'YT'},
  {name: 'Mexico', code: 'MX'},
  {name: 'Micronesia', code: 'FM'},
  {name: 'Moldova', code: 'MD'},
  {name: 'Monaco', code: 'MC'},
  {name: 'Mongolia', code: 'MN'},
  {name: 'Montserrat', code: 'MS'},
  {name: 'Morocco', code: 'MA'},
  {name: 'Mozambique', code: 'MZ'},
  {name: 'Myanmar', code: 'MM'},
  {name: 'Namibia', code: 'NA'},
  {name: 'Nauru', code: 'NR'},
  {name: 'Nepal', code: 'NP'},
  {name: 'Netherlands', code: 'NL'},
  {name: 'Netherlands Antilles', code: 'AN'},
  {name: 'New Caledonia', code: 'NC'},
  {name: 'New Zealand', code: 'NZ'},
  {name: 'Nicaragua', code: 'NI'},
  {name: 'Niger', code: 'NE'},
  {name: 'Nigeria', code: 'NG'},
  {name: 'Niue', code: 'NU'},
  {name: 'Norfolk Island', code: 'NF'},
  {name: 'Northern Mariana Islands', code: 'MP'},
  {name: 'Norway', code: 'NO'},
  {name: 'Oman', code: 'OM'},
  {name: 'Pakistan', code: 'PK'},
  {name: 'Palau', code: 'PW'},
  {name: 'Palestinian', code: 'PS'},
  {name: 'Panama', code: 'PA'},
  {name: 'Papua New Guinea', code: 'PG'},
  {name: 'Paraguay', code: 'PY'},
  {name: 'Peru', code: 'PE'},
  {name: 'Philippines', code: 'PH'},
  {name: 'Pitcairn', code: 'PN'},
  {name: 'Poland', code: 'PL'},
  {name: 'Portugal', code: 'PT'},
  {name: 'Puerto Rico', code: 'PR'},
  {name: 'Qatar', code: 'QA'},
  {name: 'Reunion', code: 'RE'},
  {name: 'Romania', code: 'RO'},
  {name: 'Russian Federation', code: 'RU'},
  {name: 'RWANDA', code: 'RW'},
  {name: 'Saint Helena', code: 'SH'},
  {name: 'Saint Kitts and Nevis', code: 'KN'},
  {name: 'Saint Lucia', code: 'LC'},
  {name: 'Saint Pierre and Miquelon', code: 'PM'},
  {name: 'Saint Vincent and the Grenadines', code: 'VC'},
  {name: 'Samoa', code: 'WS'},
  {name: 'San Marino', code: 'SM'},
  {name: 'Sao Tome and Principe', code: 'ST'},
  {name: 'Saudi Arabia', code: 'SA'},
  {name: 'Senegal', code: 'SN'},
  {name: 'Serbia', code: 'CS'},
  {name: 'Seychelles', code: 'SC'},
  {name: 'Sierra Leone', code: 'SL'},
  {name: 'Singapore', code: 'SG'},
  {name: 'Slovakia', code: 'SK'},
  {name: 'Slovenia', code: 'SI'},
  {name: 'Solomon Islands', code: 'SB'},
  {name: 'Somalia', code: 'SO'},
  {name: 'South Africa', code: 'ZA'},
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
  {name: 'Spain', code: 'ES'},
  {name: 'Sri Lanka', code: 'LK'},
  {name: 'Sudan', code: 'SD'},
  {name: 'Suriname', code: 'SR'},
  {name: 'Svalbard and Jan Mayen', code: 'SJ'},
  {name: 'Swaziland', code: 'SZ'},
  {name: 'Sweden', code: 'SE'},
  {name: 'Switzerland', code: 'CH'},
  {name: 'Syrian Arab Republic', code: 'SY'},
  {name: 'Taiwan', code: 'TW'},
  {name: 'Tajikistan', code: 'TJ'},
  {name: 'Tanzania', code: 'TZ'},
  {name: 'Thailand', code: 'TH'},
  {name: 'Timor-Leste', code: 'TL'},
  {name: 'Togo', code: 'TG'},
  {name: 'Tokelau', code: 'TK'},
  {name: 'Tonga', code: 'TO'},
  {name: 'Trinidad and Tobago', code: 'TT'},
  {name: 'Tunisia', code: 'TN'},
  {name: 'Turkey', code: 'TR'},
  {name: 'Turkmenistan', code: 'TM'},
  {name: 'Turks and Caicos Islands', code: 'TC'},
  {name: 'Tuvalu', code: 'TV'},
  {name: 'Uganda', code: 'UG'},
  {name: 'Ukraine', code: 'UA'},
  {name: 'United Arab Emirates', code: 'AE'},
  {name: 'United Kingdom', code: 'GB'},
  {name: 'United States', code: 'US'},
  {name: 'United States Minor Outlying Islands', code: 'UM'},
  {name: 'Uruguay', code: 'UY'},
  {name: 'Uzbekistan', code: 'UZ'},
  {name: 'Vanuatu', code: 'VU'},
  {name: 'Venezuela', code: 'VE'},
  {name: 'VietNam', code: 'VN'},
  {name: 'Western Sahara', code: 'EH'},
  {name: 'Yemen', code: 'YE'},
  {name: 'Zambia', code: 'ZM'},
  {name: 'Zimbabwe', code: 'ZW'}
]

export const designations = [
  {
    name: 'Anesthesia Assistant',
    id: '3993767000001562003'
  },
  {
    name: 'Behavior Analyst',
    id: '3993767000000111011'
  },
  // {
  //   name: 'Cape Cod CPR Staff',
  //   id: '3993767000000294047'
  // },
  {
    name: 'Certified Registered Nurse Anesthetist',
    id: '3993767000000111055'
  },
  {
    name: 'Chiropractor',
    id: '3993767000000111079'
  },
  {
    name: 'Dentist',
    id: '3993767000000111031'
  },
  {
    name: 'Dialysis Technician',
    id: '3993767000000111063'
  },
  {
    name: 'Doctor',
    id: '3993767000000111003'
  },
  {
    name: 'Emergency Medical Technician',
    id: '3993767000000111043'
  },
  {
    name: 'Lactation Consultant',
    id: '3993767000000111087'
  },
  {
    name: 'Medical Assistant',
    id: '3993767000000416003',
  },
  {
    name: 'Midwife',
    id: '3993767000000111039'
  },
  {
    name: 'Nurse',
    id: '3993767000000111007'
  },
  {
    name: 'Nursing Assistant',
    id: '3993767000000462009'
  },
  {
    name: 'Nurse Practitioner',
    id: '3993767000000111019'
  },
  {
    name: 'Nutritionist',
    id: '3993767000000111035'
  },
  {
    name: 'Occupational Therapist',
    id: '3993767000000111051'
  },
  {
    name: 'Optometrist',
    id: '3993767000000111067'
  },
  // {
  //   name: 'Peramedic',
  //   id: '3993767000020111947'
  // },
  {
    name: 'Pharmacist',
    id: '3993767000000111047'
  },
  {
    name: 'Phlebotomist',
    id: '3993767000000111059'
  },
  {
    name: 'Physical Therapist',
    id: '3993767000000111083'
  },
  {
    name: 'Physician Assistant',
    id: '3993767000000111015'
  },
  {
    name: 'Podiatrist',
    id: '3993767000000111023'
  },
  {
    name: 'Psychologist',
    id: '3993767000000111071'
  },
  {
    name: 'Radiation Therapist',
    id: '3993767000000111091'
  },
  {
    name: 'Radiologic Technologist',
    id: '3993767000000111095'
  },
  {
    name: 'Respiratory Therapist',
    id: '3993767000000111027'
  },
  {
    name: 'Social Worker',
    id: '3993767000000111075'
  },
  {
    name: 'Speech-Language Pathologist',
    id: '3993767000000136003'
  },
  {
    name: 'Staff',
    id: '3993767000000294013'
  },
  {
    name: 'Student',
    id: '3993767000000441009'
  },
];

export const designationsObject = {
  'Anesthesia Assistant': {
    name: 'Anesthesia Assistant',
    id: '3993767000001562003'
  },
  'Behavior Analyst':{
    name: 'Behavior Analyst',
    id: '3993767000000111011'
  },
  'Cape Cod CPR Staff':{
    name: 'Cape Cod CPR Staff',
    id: '3993767000000294047'
  },
  'Certified Registered Nurse Anesthetist':{
    name: 'Certified Registered Nurse Anesthetist',
    id: '3993767000000111055'
  },
  'Chiropractor':{
    name: 'Chiropractor',
    id: '3993767000000111079'
  },
  'Dentist':{
    name: 'Dentist',
    id: '3993767000000111031'
  },
  'Dialysis Technician':{
    name: 'Dialysis Technician',
    id: '3993767000000111063'
  },
  'Doctor':{
    name: 'Doctor',
    id: '3993767000000111003'
  },
  'Emergency Medical Technician':{
    name: 'Emergency Medical Technician',
    id: '3993767000000111043'
  },
  'Lactation Consultant':{
    name: 'Lactation Consultant',
    id: '3993767000000111087'
  },
  'Medical Assistant':{
    name: 'Medical Assistant',
    id: '3993767000000416003',

  },
  'Midwife':{
    name: 'Midwife',
    id: '3993767000000111039'
  },
  'Nurse':{
    name: 'Nurse',
    id: '3993767000000111007'
  },
  'Nursing Assistant':{
    name: 'Nursing Assistant',
    id: '3993767000000462009'
  },
  'Nurse Practitioner':{
    name: 'Nurse Practitioner',
    id: '3993767000000111019'
  },
  'Nutritionist':{
    name: 'Nutritionist',
    id: '3993767000000111035'
  },
  'Occupational Therapist':{
    name: 'Occupational Therapist',
    id: '3993767000000111051'
  },
  'Optometrist':{
    name: 'Optometrist',
    id: '3993767000000111067'
  },
  'Pharmacist':{
    name: 'Pharmacist',
    id: '3993767000000111047'
  },
  'Phlebotomist':{
    name: 'Phlebotomist',
    id: '3993767000000111059'
  },
  'Physical Therapist':{
    name: 'Physical Therapist',
    id: '3993767000000111083'
  },
  'Physician Assistant':{
    name: 'Physician Assistant',
    id: '3993767000000111015'
  },
  'Podiatrist':{
    name: 'Podiatrist',
    id: '3993767000000111023'
  },
  'Psychologist':{
    name: 'Psychologist',
    id: '3993767000000111071'
  },
  'Radiation Therapist':{
    name: 'Radiation Therapist',
    id: '3993767000000111091'
  },
  'Radiologic Technologist':{
    name: 'Radiologic Technologist',
    id: '3993767000000111095'
  },
  'Respiratory Therapist':{
    name: 'Respiratory Therapist',
    id: '3993767000000111027'
  },
  'Social Worker':{
    name: 'Social Worker',
    id: '3993767000000111075'
  },
  'Speech-Language Pathologist':{
    name: 'Speech-Language Pathologist',
    id: '3993767000000136003'
  },
  'Staff':{
    name: 'Staff',
    id: '3993767000000294013'
  },
  'Student':{
    name: 'Student',
    id: '3993767000000441009'
  },
}

export const specialityOptions = ['Allergy and Immunology', 'Anesthesiology', 'Cardiology, Echocardiography', 'Cardiology, Electrophysiology', 'Cardiology, General', 'Cardiology, Interventional', 'Cardiology, Nuclear', 'Critical Care/Intensive Care', 'Dermatology, General', 'Dermatology, Cosmetic', 'Diabetes', 'Diabetes Educator', 'Emergency Medicine', 'Endocrinology, Metabolism', 'Endocrinology, Reproductive/Infertility', 'Family Medicine', 'Gastroenterology', 'General Practice', 'Genomic Medicine', 'Geriatrics', 'Hematology', 'Hepatology', 'HIV/AIDS', 'Hospice/Palliative Medicine', 'Hospital Medicine', 'Infectious Disease', 'Internal Medicine', 'Neonatal/Perinatal Medicine', 'Nephrology', 'Neurology', 'Neurosurgery', 'Nuclear Medicine', 'Nutrition', 'OB/GYN and Women\'s Health', 'Oncology, Hematology/Oncology', 'Oncology, Medical', 'Oncology, Other', 'Oncology, Radiation', 'Ophthalmology', 'Orthopaedic Surgery', 'Otolaryngology', 'Pain Management', 'Pathology', 'Pediatrics, Allergy', 'Pediatrics, Cardiology', 'Pediatrics, General', 'Pediatrics, Oncology', 'Physical Medicine and Rehabilitation', 'Plastic Surgery and Anesthetic Medicine', 'Preventative Medicine', 'Psychiatry/Mental Health', 'Public/Community Health', 'Pulmonary Medicine', 'Radiology', 'Radiology, Interventional', 'Rheumatology', 'Surgery, Cardiothoracic', 'Surgery, General', 'Surgery, Oral and Maxillofacial', 'Surgery, Other', 'Surgery, Surgical Oncology', 'Surgery, Vascular', 'Transplantation', 'Urology', 'Vascular Medicine', 'Other Clinical', 'Non-Clinical'];
export const showSpecialtyFor = ['3993767000000111003', '3993767000000111007', '3993767000000111019', '3993767000000111015'];

export const studentProgramOptions = ['Chiropractic', 'Dentistry', 'Medicine', 'Nursing', 'Optometry', 'Pharmacy', 'Physical Therapy', 'Physician Assistant', 'Podiatry', 'Other'];
export const showProgramFor = ['3993767000000441009']; // Student only yet

export const states = [
  {
    name: 'Alabama',
    id: '3993767000000047427',
    certificates: '[{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"QACSC (PA/CRNP)","id":"3993767000000061007","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"ACSC ","id":"3993767000000061003","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Alaska',
    id: '3993767000000047115',
    certificates: '[{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"PGY-2","id":"3993767000000186655","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Arizona',
    id: '3993767000000047127',
    certificates: '[{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Arkansas',
    id: '3993767000000047139',
    certificates: '[{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"Arkansas Jurisprudence Exam","id":"3993767000000191215","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'California',
    id: '3993767000000047151',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"California Portfolio Exam","id":"3993767000000186667","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"California Practice Standards","id":"3993767000000191231","validity":"Lifetime"},{"name":"Jurisprudence Exam ","id":"3993767000000191239","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Colorado',
    id: '3993767000000047163',
    certificates: '[{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"OSCE","id":"3993767000000186787","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"Portfolio Exam","id":"3993767000000186803","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Connecticut',
    id: '3993767000000026017',
    certificates: '[{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Delaware',
    id: '3993767000000047443',
    certificates: '[{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"Delaware Practical Board Examination","id":"3993767000000186843","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Certificate Courses',
    id: '3993767000000047787',
    certificates: '[{"name":"Handtevy Instructor","id":"3993767000000315019","validity":"Expires"},{"name":"DAC Instructor","id":"3993767000000315023","validity":"Expires"},{"name":"CPR","id":"3993767000000191723","validity":"Expires"},{"name":"EMT","id":"3993767000000315027","validity":"Expires"},{"name":"Paramedic","id":"3993767000000315031","validity":"Expires"},{"name":"Registered Nurse","id":"3993767000000315035","validity":"Expires"},{"name":"ACLS","id":"3993767000000047791","validity":"Expires"},{"name":"PALS","id":"3993767000000047799","validity":"Expires"},{"name":"BLS","id":"3993767000000047807","validity":"Expires"},{"name":"NRP","id":"3993767000000047815","validity":"Expires"},{"name":"ASLS","id":"3993767000000047816","validity":"Expires"},{"name":"ATLS","id":"3993767000000047823","validity":"Expires"},{"name":"ACLS Instructor","id":"3993767000000315003","validity":"Expires"},{"name":"PALS Instructor","id":"3993767000000315007","validity":"Expires"},{"name":"BLS Instructor","id":"3993767000000315011","validity":"Expires"},{"name":"ECSI Instructor","id":"3993767000000315015","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Florida',
    id: '3993767000000047175',
    certificates: '[{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Georgia',
    id: '3993767000000047187',
    certificates: '[{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"Georgia Practical Exam ","id":"3993767000000191323","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Guam',
    id: '3993767000000055401',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Hawaii',
    id: '3993767000000047475',
    certificates: '[{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"TOFEL","id":"3993767000000191335","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"TSE","id":"3993767000000191343","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"NED Registration","id":"3993767000000061031","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Health',
    id: '3993767000000075003',
    certificates: '[{"name":"COVID-19 Vaccine","id":"3993767000000818003","validity":"Expires"},{"name":"Drug Screen","id":"3993767000000818007","validity":"Expires"},{"name":"Hepatitis B Vaccine","id":"3993767000000818011","validity":"Expires"},{"name":"Influenza Vaccination","id":"3993767000000075011","validity":"Expires"},{"name":"Measles, Mumps & Rubella Vaccine","id":"3993767000000818015","validity":"Expires"},{"name":"PPD","id":"3993767000000623013","validity":"Expires"},{"name":"Tdap Vaccine","id":"3993767000000818023","validity":"Expires"},{"name":"Varicella Vaccine","id":"3993767000000818027","validity":"Expires"}]'
  },
  {
    name: 'Idaho',
    id: '3993767000000047723',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"TOFEL","id":"3993767000000191335","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"TSE","id":"3993767000000191343","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Illinois',
    id: '3993767000000047491',
    certificates: '[{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Indiana',
    id: '3993767000000047507',
    certificates: '[{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Iowa',
    id: '3993767000000047523',
    certificates: '[{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"California Portfolio Exam","id":"3993767000000186667","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"DLOSE","id":"3993767000000186671","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Kansas',
    id: '3993767000000047199',
    certificates: '[{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Kentucky',
    id: '3993767000000047211',
    certificates: '[{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Louisiana',
    id: '3993767000000047539',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDS","id":"3993767000000061015","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Maine',
    id: '3993767000000026029',
    certificates: '[{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Maryland',
    id: '3993767000000047555',
    certificates: '[{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDS","id":"3993767000000061015","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Massachusetts',
    id: '3993767000000019003',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CDCA DSE","id":"3993767000000188047","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"Massachusetts CSR","id":"3993767000000019031","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"XDEA","id":"3993767000000019039","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Michigan',
    id: '3993767000000047571',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"Controlled Substance License","id":"3993767000000061035","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"OSCE","id":"3993767000000186787","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Minnesota',
    id: '3993767000000047235',
    certificates: '[{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"OSCE","id":"3993767000000186787","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Mississippi',
    id: '3993767000000047247',
    certificates: '[{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Missouri',
    id: '3993767000000047591',
    certificates: '[{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"BNDD Practitioner","id":"3993767000000061019","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"BNDD Mid-Level","id":"3993767000000061023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Montana',
    id: '3993767000000047259',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Nebraska',
    id: '3993767000000047271',
    certificates: '[{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Nevada',
    id: '3993767000000047743',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'New Hampshire',
    id: '3993767000000020003',
    certificates: '[{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'New Mexico',
    id: '3993767000000047615',
    certificates: '[{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'New York',
    id: '3993767000000026021',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"Assessment Training ","id":"3993767000000192011","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"Board Certified Behavior Analyst Certification ","id":"3993767000000200003","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"Child Abuse Identification and Reporting Certificate ","id":"3993767000000200011","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"HIPAA Awareness Training certificate ","id":"3993767000000200019","validity":"Expires"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"Sexual Harassment Training Certificate ","id":"3993767000000200027","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"Restraint Training ","id":"3993767000000200035","validity":"Expires"},{"name":"Teaching Certification ","id":"3993767000000200043","validity":"Lifetime"},{"name":"BCBA/BCaBA Supervision Certification ","id":"3993767000000200051","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'New Jersey',
    id: '3993767000000026033',
    certificates: '[{"validity":"Expires","id":"3993767000000047099","name":"CSR"},{"validity":"Expires","id":"3993767000000019023","name":"DEA"},{"validity":"Expires","id":"3993767000000026009","name":"License"},{"validity":"Expires","id":"3993767000000186019","name":"NBRC"},{"validity":"Expires","id":"3993767000000186003","name":"RRT"},{"validity":"Expires","id":"3993767000000186011","name":"CRT"},{"validity":"Lifetime","id":"3993767000000186639","name":"CDCA"},{"validity":"Lifetime","id":"3993767000000186643","name":"CITA"},{"validity":"Lifetime","id":"3993767000000191159","name":"FPGEC"},{"validity":"Lifetime","id":"3993767000000191151","name":"NAPLEX"},{"validity":"Lifetime","id":"3993767000000191155","name":"MPJE"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'North Carolina',
    id: '3993767000000047283',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'North Dakota',
    id: '3993767000000047311',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'North Marina Islands',
    id: '3993767000000055404',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Ohio',
    id: '3993767000000047095',
    certificates: '[{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"TSE","id":"3993767000000191343","validity":"Lifetime"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"TOFEL","id":"3993767000000191335","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Oklahoma',
    id: '3993767000000047631',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"OBNDD Registration ","id":"3993767000000061039","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Oregon',
    id: '3993767000000047327',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"DCLOSE","id":"3993767000000188371","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Pennsylvania',
    id: '3993767000000047339',
    certificates: '[{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Puerto Rico',
    id: '3993767000000055403',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Rhode Island',
    id: '3993767000000047647',
    certificates: '[{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'South Carolina',
    id: '3993767000000047671',
    certificates: '[{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'South Dakota',
    id: '3993767000000047759',
    certificates: '[{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"Jurisprudence Exam ","id":"3993767000000191239","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"SD CSR","id":"3993767000000061043","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Tennessee',
    id: '3993767000000047351',
    certificates: '[{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Texas',
    id: '3993767000000047363',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Training',
    id: '3993767000000075007',
    certificates: '[{"name":"Medical School Diploma","id":"3993767000000075019","validity":"Lifetime"}]'
  },
  {
    name: 'Utah',
    id: '3993767000000047687',
    certificates: '[{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'US Virgin Islands',
    id: '3993767000000055402',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Vermont',
    id: '3993767000000026025',
    certificates: '[{"name":"FPGEE","id":"3993767000000191795","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"TOFEL","id":"3993767000000191335","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"TSE","id":"3993767000000191343","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Virginia',
    id: '3993767000000047379',
    certificates: '[{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"VA Law Exam","id":"3993767000000191823","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Washington',
    id: '3993767000000047391',
    certificates: '[{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"OSCE","id":"3993767000000186787","validity":"Lifetime"},{"name":"PGY-1","id":"3993767000000186663","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"DLOSE","id":"3993767000000186671","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Washington DC',
    id: '3993767000000047459',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"CSR","id":"3993767000000047099","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'West Virginia',
    id: '3993767000000047403',
    certificates: '[{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"CRDTS","id":"3993767000000186647","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"WV Errors and Omissions Exam","id":"3993767000000191855","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Wisconsin',
    id: '3993767000000047415',
    certificates: '[{"name":"MPJE","id":"3993767000000191155","validity":"Lifetime"},{"name":"CDCA","id":"3993767000000186639","validity":"Lifetime"},{"name":"NBRC","id":"3993767000000186019","validity":"Expires"},{"name":"CITA","id":"3993767000000186643","validity":"Lifetime"},{"name":"RRT","id":"3993767000000186003","validity":"Expires"},{"name":"CRT","id":"3993767000000186011","validity":"Expires"},{"name":"SRTA","id":"3993767000000186659","validity":"Lifetime"},{"name":"WREB","id":"3993767000000186651","validity":"Lifetime"},{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"DEA","id":"3993767000000019023","validity":"Expires"},{"name":"FPGEC","id":"3993767000000191159","validity":"Lifetime"},{"name":"NAPLEX","id":"3993767000000191151","validity":"Lifetime"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  },
  {
    name: 'Transcripts and Scores',
    id: '3993767000000078039',
    certificates: '[{"name":"Transcripts & Scores","id":"3993767000000566023","validity":"Expires"}]'
  },
  {
    name: 'Malpractice',
    id: '3993767000000078035',
    certificates: '[{"name":"Malpractice","id":"3993767000000461019","validity":"Expires"}]'
  },
  {
    name: 'Wyoming',
    id: '3993767000000023401',
    certificates: '[{"name":"License","id":"3993767000000026009","validity":"Expires"},{"name":"License-Physician","id":"3993767000000019870","validity":"Expires"},{"name":"License-Physician Assistant","id":"3993767000000020871","validity":"Expires"},{"name":"License-RN","id":"3993767000000021872","validity":"Expires"},{"name":"License-APRN","id":"3993767000000022873","validity":"Expires"},{"name":"License-CRNA","id":"3993767000000022874","validity":"Expires"}]'
  }
];

export const cme_states = [
  {
    name: 'Alabama',
    id: '3993767000000047427',
  },
  {
    name: 'Alaska',
    id: '3993767000000047115',
  },
  {
    name: 'Arizona',
    id: '3993767000000047127',
  },
  {
    name: 'Arkansas',
    id: '3993767000000047139',
  },
  {
    name: 'California',
    id: '3993767000000047151',
  },
  {
    name: 'Colorado',
    id: '3993767000000047163',
  },
  {
    name: 'Connecticut',
    id: '3993767000000026017',
  },
  {
    name: 'Delaware',
    id: '3993767000000047443',
  },
  {
    name: 'Florida',
    id: '3993767000000047175',
  },
  {
    name: 'Georgia',
    id: '3993767000000047187',
  },
  {
    name: 'Hawaii',
    id: '3993767000000047475',
  },
  {
    name: 'Idaho',
    id: '3993767000000047723',
  },
  {
    name: 'Illinois',
    id: '3993767000000047491',
  },
  {
    name: 'Indiana',
    id: '3993767000000047507',
  },
  {
    name: 'Iowa',
    id: '3993767000000047523',
  },
  {
    name: 'Kansas',
    id: '3993767000000047199',
  },
  {
    name: 'Kentucky',
    id: '3993767000000047211',
  },
  {
    name: 'Louisiana',
    id: '3993767000000047539',
  },
  {
    name: 'Maine',
    id: '3993767000000026029',
  },
  {
    name: 'Maryland',
    id: '3993767000000047555',
  },
  {
    name: 'Massachusetts',
    id: '3993767000000019003',
  },
  {
    name: 'Michigan',
    id: '3993767000000047571',
  },
  {
    name: 'Minnesota',
    id: '3993767000000047235',
  },
  {
    name: 'Mississippi',
    id: '3993767000000047247',
  },
  {
    name: 'Missouri',
    id: '3993767000000047591',
  },
  {
    name: 'Montana',
    id: '3993767000000047259',
  },
  {
    name: 'Nebraska',
    id: '3993767000000047271',
  },
  {
    name: 'Nevada',
    id: '3993767000000047743',
  },
  {
    name: 'New Hampshire',
    id: '3993767000000020003',
  },
  {
    name: 'New Mexico',
    id: '3993767000000047615',
  },
  {
    name: 'New York',
    id: '3993767000000026021',
  },
  {
    name: 'New Jersey',
    id: '3993767000000026033',
  },
  {
    name: 'North Carolina',
    id: '3993767000000047283',
  },
  {
    name: 'North Dakota',
    id: '3993767000000047311',
  },
  {
    name: 'Ohio',
    id: '3993767000000047095',
  },
  {
    name: 'Oklahoma',
    id: '3993767000000047631',
  },
  {
    name: 'Oregon',
    id: '3993767000000047327',
  },
  {
    name: 'Pennsylvania',
    id: '3993767000000047339',
  },
  {
    name: 'Rhode Island',
    id: '3993767000000047647',
  },
  {
    name: 'South Carolina',
    id: '3993767000000047671',
  },
  {
    name: 'South Dakota',
    id: '3993767000000047759',
  },
  {
    name: 'Tennessee',
    id: '3993767000000047351',
  },
  {
    name: 'Texas',
    id: '3993767000000047363',
  },
  {
    name: 'Utah',
    id: '3993767000000047687',
  },
  {
    name: 'Vermont',
    id: '3993767000000026025',
  },
  {
    name: 'Virginia',
    id: '3993767000000047379',
  },
  {
    name: 'Washington',
    id: '3993767000000047391',
  },
  {
    name: 'Washington DC',
    id: '3993767000000047459',
  },
  {
    name: 'West Virginia',
    id: '3993767000000047403',
  },
  {
    name: 'Wisconsin',
    id: '3993767000000047415',
  },
  {
    name: 'Wyoming',
    id: '3993767000000023401',
  }
];


export const allCertificates = [
  {
    name: 'ACLS',
    id: '3993767000000047791'
  },
  {
    name: 'ACLS Instructor',
    id: '3993767000000315003'
  },
  {
    name: 'ACSC ',
    id: '3993767000000061003'
  },
  {
    name: 'Arkansas Jurisprudence Exam',
    id: '3993767000000191215'
  },
  {
    name: 'ATLS',
    id: '3993767000000047823'
  },
  {
    name: 'BLS',
    id: '3993767000000047807'
  },
  {
    name: 'BLS Instructor',
    id: '3993767000000315011'
  },
  {
    name: 'California Portfolio Exam',
    id: '3993767000000186667'
  },
  {
    name: 'California Practice Standards',
    id: '3993767000000191231'
  },
  {
    name: 'CDCA',
    id: '3993767000000186639'
  },
  {
    name: 'CITA',
    id: '3993767000000186643'
  },
  {
    name: 'CPR',
    id: '3993767000000191723'
  },
  {
    name: 'CRDTS',
    id: '3993767000000186647'
  },
  {
    name: 'CRT',
    id: '3993767000000186011'
  },
  {
    name: 'CSR',
    id: '3993767000000047099'
  },
  {
    name: 'DAC Instructor',
    id: '3993767000000315023'
  },
  {
    name: 'DEA',
    id: '3993767000000019023'
  },
  {
    name: 'Delaware Practical Board Examination',
    id: '3993767000000186843'
  },
  {
    name: 'ECSI Instructor',
    id: '3993767000000315015'
  },
  {
    name: 'EMT',
    id: '3993767000000315027'
  },
  {
    name: 'FPGEC',
    id: '3993767000000191159'
  },
  {
    name: 'Georgia Practical Exam ',
    id: '3993767000000191323'
  },
  {
    name: 'Handtevy Instructor',
    id: '3993767000000315019'
  },
  {
    name: 'Influenza Vaccination',
    id: '3993767000000075011'
  },
  {
    name: 'Jurisprudence Exam ',
    id: '3993767000000191239'
  },
  {
    name: 'License',
    id: '3993767000000026009'
  },
  {
    name: 'License-Physician',
    id: '3993767000000019870'
  },
  {
    name: 'License-Physician Assistant',
    id: '3993767000000020871'
  },
  {
    name: 'License-APRN',
    id: '3993767000000022873'
  },
  {
    name: 'License-RN',
    id: '3993767000000021872'
  },
  {
    name: 'MPJE',
    id: '3993767000000191155'
  },
  {
    name: 'NAPLEX',
    id: '3993767000000191151'
  },
  {
    name: 'NBRC',
    id: '3993767000000186019'
  },
  {
    name: 'NED Registration',
    id: '3993767000000061031'
  },
  {
    name: 'NRP',
    id: '3993767000000047815'
  },
  {
    name: 'ASLS',
    id: '3993767000000047816'
  },
  {
    name: 'OSCE',
    id: '3993767000000186787'
  },
  {
    name: 'PALS',
    id: '3993767000000047799'
  },
  {
    name: 'PALS Instructor',
    id: '3993767000000315007'
  },
  {
    name: 'Paramedic',
    id: '3993767000000315031'
  },
  {
    name: 'PGY-1',
    id: '3993767000000186663'
  },
  {
    name: 'PGY-2',
    id: '3993767000000186655'
  },
  {
    name: 'Portfolio Exam',
    id: '3993767000000186803'
  },
  {
    name: 'QACSC (PA/CRNP)',
    id: '3993767000000061007'
  },
  {
    name: 'Registered Nurse',
    id: '3993767000000315035'
  },
  {
    name: 'RRT',
    id: '3993767000000186003'
  },
  {
    name: 'SRTA',
    id: '3993767000000186659'
  },
  {
    name: 'TOFEL',
    id: '3993767000000191335'
  },
  {
    name: 'TSE',
    id: '3993767000000191343'
  },
  {
    name: 'WREB',
    id: '3993767000000186651'
  },
  {
    name: 'License-CRNA',
    id: '3993767000000022874'
  }
];

export const certificatesDictionary = {
  onHold: {
    text: 'On Hold',
    color: '#f69243',
    image: 'hold.png',
  },
  cancelled: {
    text: 'Cancelled',
    color: '#f69243',
    image: 'hold.png',
  },
  withdrawn: {
    text: 'Withdrawn',
    color: '#f69243',
    image: 'hold.png',
  },
  full: {
    text: '150+',
    color: '#5d983b',
    image: 'greenfull.png',
  },
  fullQuarter: {
    text: '120+',
    color: '#4a9767',
    image: 'greenquarters.png',
  },
  goingToExpire: {
    text: '90+',
    color: '#f69243',
    image: 'goingToExpire.png',
  },
  low: {
    text: '60+',
    color: '#d0a521',
    image: 'low.png',
  },
  lowest: {
    text: '30+',
    color: '#FF0000',
    image: 'lowest.png',
  },
  default: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'default.png',
  },
  initiated: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'initiated.svg',
  },
  application_submitted: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'application_submitted.svg',
  },
  verifications: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'verifications.svg',
  },
  application_complete: {
    text: '',
    color: 'rgba(26, 92, 234, 0.68)',
    image: 'application_complete.svg',
  },
};
export const courseDetails = [
  {
    name: 'acls-course',
    full_name: 'Advanced Cardiovascular Life Support',
    short_name: 'ACLS',
    id: 4526,
    time_in_minutes: 600,
    cme_credits: 4.25,
    google_event: 'ACLS Start Now Button',
  },
  {
    name: 'bls-course',
    full_name: 'Basic Life Support',
    short_name: 'BLS',
    id: 9985,
    time_in_minutes: 600,
    cme_credits: 3,
    google_event: 'BLS Start Now Button',
  },
  {
    name: 'pals-course',
    full_name: 'Pediatric Advanced Life Support',
    short_name: 'PALS',
    id: 9238,
    time_in_minutes: 720,
    cme_credits: 4,
    google_event: 'PALS Start Now Button',
  },
  {
    name: 'ecg-course',
    full_name: 'Electrocardiogram',
    short_name: 'ECG',
    id: 130360,
    time_in_minutes: 600,
    cme_credits: 3,
    google_event: 'ECG Start Now Button',
  },
  {
    name: 'nrp-course',
    full_name: 'Neonatal Resuscitation Program',
    short_name: 'NRP',
    id: 79132,
    time_in_minutes: 600,
    cme_credits: 3, 
    google_event: 'NRP Start Now Button',
  },
  {
    name: 'NIH-stroke-scale',
    full_name: 'NIH Stroke Scale',
    short_name: 'NIHSS',
    id: 192797,
    time_in_minutes: 600,
    cme_credits: 2, 
    google_event: 'NIHSS Start Now Button',
  },
  {
    name: 'asls-course',
    full_name: 'Annual Stroke Center Continuing Education (ASC CE)',
    short_name: 'ASLS',
    id: 151904,
    time_in_minutes: 600,
    cme_credits: 3,
    google_event: 'ASLS Start Now Button',
  },
];

export const certCategories = [
  {
    name: 'Certificate Courses',
    id: '3993767000000047787',
    description: 'e.g ACLS, BLS, PALS'
  },
  {
    name: 'CME/CE',
    id: 'cme',
    description: 'e.g Pain Management, Opioid Best Practices'
  },
  {
    name: 'Health',
    id: '3993767000000075003',
    description: 'e.g Influenza/COVID-19 Vaccine'
  },
  {
    name: 'Malpractice',
    id: '3993767000000078035'
  },
  {
    name: 'Regulatory',
    id: 'show_states',
    description: 'e.g State Licenses'
  },
  {
    name: 'Transcripts and Scores',
    id: '3993767000000078039'
  }
];

export const creditTypes = [
  {
    name: 'AMA PRA Category 1',
    id: '3993767000000230065'
  },
  {
    name: 'AMA PRA Category 2',
    id: '3993767000000230066'
  },
];

export const specificDesignations= [
  {
    name: "Select Category",
    id: "3993767000000752003"
  },
  {
    name: "General",
    id: "3993767000000230086"
  },
  {
      name: "Antimicrobial Stewardship",
      id: "3993767000000752079"
  },
  {
      name: "Bioterrorism",
      id: "3993767000000752075"
  },
  {
      name: "Suicide Prevention",
      id: "3993767000000752071"
  },
  {
      name: "Treatment of Patients with Cognitive Impairments",
      id: "3993767000000752067"
  },
  {
      name: "Behavioral Health",
      id: "3993767000000752063"
  },
  {
      name: "Cultural Awareness",
      id: "3993767000000752059"
  },
  {
      name: "Human Trafficking",
      id: "3993767000000752055"
  },
  {
      name: "Sexual and Physical Abuse",
      id: "3993767000000752051"
  },
  {
      name: "Domestic Violence",
      id: "3993767000000752047"
  },
  {
      name: "Sexual Violence",
      id: "3993767000000752043"
  },
  {
      name: "Pharmacology",
      id: "3993767000000752039"
  },
  {
      name: "HIV",
      id: "3993767000000752035"
  },
  {
      name: "Infection Control",
      id: "3993767000000752031"
  },
  {
      name: "Infectious Disease",
      id: "3993767000000752027"
  },
  {
      name: "Medical Error Prevention",
      id: "3993767000000752023"
  },
  {
      name: "Record Keeping",
      id: "3993767000000752019"
  },
  {
      name: "Board Regulations",
      id: "3993767000000752015"
  },
  {
      name: "Scope of Practice",
      id: "3993767000000752011"
  },
  {
      name: "Medical Ethics",
      id: "3993767000000752007"
  },
  {
      name: "End of Life Care",
      id: "3993767000000230081"
  },
  {
      name: "Risk Management",
      id: "3993767000000230077"
  },
  {
      name: "Opioid Education",
      id: "3993767000000230069"
  },
  {
      name: "Alzheimer's Disease",
      id: "3993767000000230082"
  },
  {
      name: "Child Abuse",
      id: "3993767000000230083"
  },
  {
      name: "Dementia",
      id: "3993767000000230084"
  },
  {
      name: "Electronic Health Records",
      id: "3993767000000230085"
  },
  {
      name: "Implicit Bias Training",
      id: "3993767000000230087"
  },
  {
      name: "Pain Management",
      id: "3993767000000230088"
  },
  {
      name: "Palliative Care",
      id: "3993767000000230089"
  },
  {
      name: "Patient Safety",
      id: "3993767000000230090"
  },
  {
      name: "Prescribing Controlled Substances",
      id: "3993767000000230091"
  },
  {
      name: "Record Keeping",
      id: "3993767000000230092"
  },
  {
      name: "Substance Abuse",
      id: "3993767000000230093"
  },
  {
    name: "Public Health Needs",
    id: "3993767000000230094"
  },
  {
    name: "Stroke",
    id: "3993767000000230095"
  },
  {
    name: "Pediatrics",
    id: "3993767000000230096"
  },
  {
    name: "Trauma",
    id: "3993767000000230097"
  },
  {
    name: "Geriatric Medicine",
    id: "3993767000000230098"
  },
]



export const staticCMECourses = [
  {
    name: 'Opioid Best Practices',
    slug: 'skeleton',
    isStatic: true,
    cme_credits: 1,
    url: 'http://pafp.com/Pages/Content.aspx?id=1698',
    filterKeyword: 'opioid'
  },
  {
    name: 'Opioid Prescriber Training Program: 1',
    slug: 'CME in-app graphics-03',
    isStatic: true,
    cme_credits: 2,
    url: "http://pharmacy.buffalo.edu/academics/continuing-education/events/opioid-prescriber-training-program-part-11.html",
    filterKeyword: 'pain-management'
  },
  {
    name: 'Opioid Prescriber Training Program: 2',
    slug: 'CME part 2',
    isStatic: true,
    cme_credits: 2,
    url: 'http://pharmacy.buffalo.edu/academics/continuing-education/events/opioid-prescriber-training-program-part-21.html',
    filterKeyword: 'pain-management'
  },
  {
    name: 'Non-Pharmaceutical Options for Chronic Pain',
    slug: 'CME in-app graphics-02',
    isStatic: true,
    cme_credits: 1,
    url: 'https://www.pafp.com/Pages/Content.aspx?id=1704',
    filterKeyword: 'pain-management'
  },
  // {
  //   name: 'COVID 19: Maternity Care',
  //   slug: 'Maternity',
  //   isStatic: true,
  //   cme_credits: 1,
  //   url: 'https://lms.aafp.org/node/42022?#group-tabs-node-course-default1',
  //   filterKeyword: 'covid'
  // },
  // {
  //   name: 'COVID-19: Providing Comprehensive Pediatric Care During COVID-19',
  //   slug: 'Pediatric',
  //   isStatic: true,
  //   cme_credits: 1,
  //   url: 'https://lms.aafp.org/node/42104?#group-tabs-node-course-default1',
  //   filterKeyword: 'covid'
  // },
  // {
  //   name: 'COVID-19 Applied Cases: Symptomatic And Asymptomatic Patients',
  //   slug: 'Patients',
  //   isStatic: true,
  //   cme_credits: 1.25,
  //   url: 'https://lms.aafp.org/node/42010?#group-tabs-node-course-default1',
  //   filterKeyword: 'covid'
  // },
  // {
  //   name: 'COVID-19 Applied Cases: Mild, Moderate, And Severe Symptoms',
  //   slug: 'Symptoms',
  //   isStatic: true,
  //   cme_credits: 1,
  //   url: 'https://lms.aafp.org/node/42006?#group-tabs-node-course-default1',
  //   filterKeyword: 'covid'
  // },
  // {
  //   name: 'COVID-19: Virus Recovery And Treatment',
  //   slug: 'Virus Recovery',
  //   isStatic: true,
  //   cme_credits: 1,
  //   url: 'https://lms.aafp.org/node/42080?#group-tabs-node-course-default1',
  //   filterKeyword: 'covid'
  // },
  // {
  //   name: 'COVID-19 Testing: Fundamental And Application To Practise',
  //   slug: 'Testing',
  //   isStatic: true,
  //   cme_credits: 1,
  //   url: 'https://lms.aafp.org/node/42076?#group-tabs-node-course-default1',
  //   filterKeyword: 'covid'
  // },
  // {
  //   name: 'Managing The COVID-19 Crisis: Maintaining The Emotional Health Of You, Your Team, And Your Patients',
  //   slug: 'Managing',
  //   isStatic: true,
  //   cme_credits: 1,
  //   url: 'https://lms.aafp.org/node/42034?#group-tabs-node-course-default1',
  //   filterKeyword: 'covid'
  // },
  {
    name: 'Antimicrobial Stewardship: Improving Clinical Outcomes by Optimization of Antibiotic Practices',
    slug: 'image-1',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=20868',
    filterKeyword: 'antimicrobial-stewardship'
  },
  {
    name: 'Antimicrobial Stewardship: To Prescribe or Not To Prescribe? Antibiotics and Outpatient Infections',
    slug: 'image-2',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=20758',
    filterKeyword: 'antimicrobial-stewardship'
  },
  {
    name: 'Behavior Health: Identifying Early Signs of Psychosis in Adolescents and Young Adults',
    slug: 'image-3',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=20951',
    filterKeyword: 'behavior-health'
  },
  {
    name: 'Culture Competency: Health Across the Gender Spectrum',
    slug: 'image-5',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=20959',
    filterKeyword: 'culture-competency'
  },
  {
    name: 'Medical Error Prevention: CSI- ME Case Studies In Medical Errors',
    slug: 'image-6',
    isStatic: true,
    cme_credits: 1,
    url: 'https://stanford.cloud-cme.com/course/courseoverview?P=0&EID=23777',
    filterKeyword: 'medical-error-prevention'
  },
  {
    name: 'Medical Error Prevention: Medical Errors',
    slug: 'image-10',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-2-medical-errors',
    filterKeyword: 'medical-error-prevention, medical-ethics'
  },
  {
    name: 'Medical Ethics: Clinical Decision Making',
    slug: 'image-8',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-7-clinical-decision-making',
    filterKeyword: 'record-keeping, medical-ethics'
  },
  {
    name: 'Cultural Competency: Social Determinants of Health and Cultural Competency',
    slug: 'image-4',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-1-social-determinants-health-and-cultural-competency',
    filterKeyword: 'medical-ethics, culture-competency'
  },
  {
    name: 'Medical Ethics: Conflicts of Interest',
    slug: 'image-11',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-6-conflicts-interest',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Effective Communication and Conflict Management',
    slug: 'image-12',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-3-effective-communication-and-conflict-management',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Ethical Problem Solving',
    slug: 'image-13',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-4-ethical-problem-solving',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Healthcare Economics',
    slug: 'image-14',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-8-healthcare-economics',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Impaired Physicians',
    slug: 'image-9',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-10-impaired-physicians',
    filterKeyword: 'medical-ethics, risk-management'
  },
  {
    name: 'Medical Ethics: Pediatrics Ethics',
    slug: 'image-15',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-9-pediatrics-ethics',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Medical Ethics: Professionalism in Practice',
    slug: 'image-16',
    isStatic: true,
    cme_credits: 1,
    url: 'https://cpd.education.bcm.edu/content/module-5-professionalism-practice',
    filterKeyword: 'medical-ethics'
  }
]

export const cmeCoursesFilter = [
  {
    name: 'Antimicrobial Stewardship',
    filterKeyword: 'antimicrobial-stewardship'
  },
  {
    name: 'Behavior Health',
    filterKeyword: 'behavior-health'
  },
  {
    name: 'COVID-19',
    filterKeyword: 'covid'
  },
  {
    name: 'Culture Competency',
    filterKeyword: 'culture-competency'
  },
  {
    name: 'Medical Error Prevention',
    filterKeyword: 'medical-error-prevention'
  },
  {
    name: 'Medical Ethics',
    filterKeyword: 'medical-ethics'
  },
  {
    name: 'Opioid Education',
    filterKeyword: 'opioid'
  },
  {
    name: 'Pain Management',
    filterKeyword: 'pain-management'
  },
  {
    name: 'Record Keeping',
    filterKeyword: 'record-keeping'
  },
  {
    name: 'Risk Management',
    filterKeyword: 'risk-management'
  },


]

export const staticShortCourses = [
  {
    name: 'A case of progressive headache and blurring of vision',
    slug: 'headache',
    isStatic: true,
    url: 'https://medtigo.com/quiz/a-case-of-progressive-headache-and-blurring-of-vision/',
  },
  {
    name: 'A man with sudden chest pain and hypertension',
    slug: 'hypertension',
    isStatic: true,
    url: ' https://medtigo.com/quiz/a-man-with-sudden-chest-pain-and-hypertension/ ',
  },
  {
    name: 'A young male with features of acute myocardial infarction',
    slug: 'myocardial',
    isStatic: true,
    url: '  https://medtigo.com/quiz/a-young-male-with-features-of-acute-myocardial-infarction/ ',
  },
  {
    name: 'A child with sudden hip pain and limp',
    slug: 'hip-pain',
    isStatic: true,
    url: ' https://medtigo.com/quiz/a-child-with-sudden-hip-pain-and-limp/',
  },
  {
    name: 'A young man with unexpected hematuria and right flank pain',
    slug: 'hematuria',
    isStatic: true,
    url: ' https://medtigo.com/quiz/a-young-man-with-unexpected-hematuria-and-right-flank-pain/',
  },
  {
    name: 'A case of syncope',
    slug: 'graph',
    isStatic: true,
    url: ' https://medtigo.com/quiz/a-case-of-syncope/',
  },
  {
    name: 'A case of pain in the knee?',
    slug: 'knee-pain',
    isStatic: true,
    url: ' https://medtigo.com/a-case-of-pain-in-the-knee/',
  },
  {
    name: 'Pediatric vomiting: A red flag?',
    slug: 'vomit',
    isStatic: true,
    url: 'https://medtigo.com/pediatric-vomiting-a-red-flag/',
  },
  {
    name: 'A case of chronic cough',
    slug: 'heart-beat',
    isStatic: true,
    url: 'https://medtigo.com/quiz/a-case-of-chronic-cough/',
  },
  {
    name: 'Losing too much hair',
    slug: 'hair',
    isStatic: true,
    url: 'https://medtigo.com/losing-too-much-hair/',
  },
  {
    name: 'Rash and strawberry tongue in children',
    slug: 'tongue',
    isStatic: true,
    url: 'https://medtigo.com/rash-and-strawberry-tongue-in-children/'
  },
  {
    name: 'A rare case of autoimmune disease',
    slug: 'desc-1',
    isStatic: true,
    url: 'https://medtigo.com/granulomatosis-with-polyangiitis/',
  },
]

export const siteList = [
  "Anna Jaques Hospital",
  "Bassett Medical Center",
  "Baystate Franklin Medical Center",
  "Berkshire Medical Center",
  "Bradley Memorial Hospital",
  "Charlotte Hungerford Hospital",
  "Charlton Hospital",
  "Cooley Dickinson Hospital",
  "Emerson Hospital",
  "Fairview Hospital",
  "Good Samaritan Medical Center",
  "Heywood Hospital",
  "Holy Family Hospital",
  "Holyoke Medical Center",
  "Lawrence General Hospital",
  "Little Falls Hospital",
  "Lowell General Hospital",
 " Martha's Vineyard Hospital",
  "Mercy Medical Center",
  "Midstate Medical Center",
  "North Shore Medical Center",
  "Rochester General Hospital",
  "Saint Francis Medical Center",
  "Southern New Hampshire Medical Center",
  "Southwestern Vermont Medical Center"
]

export const serviceLine = [
  {
    "name":"Anesthesiology",
    "amount":250
  },
  {
    "name":"Critical Care",
    "amount":250
  },
  {
    "name":"Critical Care | Nurse Practitioner",
    "amount":100
  },
  {
    "name":"Emergency Medicine",
    "amount":250
  },
  {
    "name":"Emergency Medicine | Physician Assistant",
    "amount":100
  },
  {
    "name":"Hospitalist - Adult",
    "amount":200
  },
  {
    "name":"Hospitalist - Med/Ped",
    "amount":225
  },
  {
    "name":"Hospitalist | Nurse Practitioner",
    "amount":100
  },
  {
    "name":"Psychiartry",
    "amount":250
  },
  {
    "name":"Urgent Care",
    "amount":175
  }
]

export const CertificateVariants=[
  // ECG ---------------->>
  // {
  //   id: '3993767000000047816',
  //   order_item_id:17475,
  //   Title: 'Electrocardiogram',
  //   Price: '0',
  //   Course_id:130360,
  //   Validity_In_Year:0,
  //   CME_Available:false,
  //   name: 'ECG',
  // },
  // NIHSS ---------------->>
  {
    id: '3993767000000047817',
    order_item_id:77817,
    Title: 'NIH Stroke Scale',
    Price: '10',
    Course_id:192797,
    Validity_In_Year:2,
    CME_Available:true,
    Full_Size_PDF_Certificate:false,
    name: 'NIHSS',
  },  
  // ASLS ---------------->>
  {
      id: '3993767000000047816',
      order_item_id:17475,
      Title: 'Advanced Stroke Life Support',
      Price: '0',
      Course_id:151904,
      Validity_In_Year:0,
      CME_Available:false,
      Full_Size_PDF_Certificate:false,
      name: 'ASLS',
  },
  // NRP ---------------->>
  {
      id: '3993767000000047815',
      order_item_id:17474,
      Title: 'Neonatal Resuscitation Program',
      Price: '0',
      Course_id:79132,
      Validity_In_Year:0,
      CME_Available:false,
      Full_Size_PDF_Certificate:false,
      name: 'NRP',
  },
  // OPIOD ---------------->>
  {
      id: '3993767000000650003',
      order_item_id: 6025,
      Title: 'Opioid Education and Pain Management',
      Price: '30',
      Course_id:11159,
      Validity_In_Year:0.5,
      Full_Size_PDF_Certificate:false,
      name: 'OPIOID',
  },
  // ALL ACLS VARIANT ---------------->>
  {
      id: '3993767000000047791',
      order_item_id: 19986,
      Title: 'Advanced Cardiovascular Life Support (ACLS) (Best Value)',
      Price: '50',
      Course_id:4526,
      Validity_In_Year:2,
      CME_Available:true,
      Full_Size_PDF_Certificate:true,
      name: 'ACLS',
    },
    {
      id: '3993767000000047791',
      order_item_id: 1036,
      Title: 'ACLS Bundle',
      Price: '45',
      Course_id:4526,
      Validity_In_Year:1,
      CME_Available:false,
      Full_Size_PDF_Certificate:true,
      name: 'ACLS',
    },
    {
      id: '3993767000000047791',
      order_item_id: 19987,
      Title: 'Advanced Cardiovascular Life Support (ACLS) (Basic)',
      Price: '30',
      Course_id:4526,
      Validity_In_Year:0.5,
      CME_Available:false,
      Full_Size_PDF_Certificate:false,
      name: 'ACLS',
    },
  // ALL BLS VARIANT ---------------->>
    {
      id: '3993767000000047807',
      order_item_id: 19981,
      Title: 'Basic Life Support (BLS) (Best Value)',
      Price: '50',
      Course_id:9985,
      Validity_In_Year:2,
      CME_Available:true,
      Full_Size_PDF_Certificate:true,
      name: 'BLS',
    },
    {
      id: '3993767000000047807',
      order_item_id: 1041,
      Title: 'BLS Bundle',
      Price: '45',
      Course_id:9985,
      Validity_In_Year:1,
      CME_Available:false,
      Full_Size_PDF_Certificate:true,
      name: 'BLS',
    },
    {
      id: '3993767000000047807',
      order_item_id: 19982,
      Title: 'Basic Life Support (BLS) (Basic)',
      Price: '30',
      Course_id:9985,
      Validity_In_Year:0.5,
      CME_Available:false,
      Full_Size_PDF_Certificate:false,
      name: 'BLS',
    },
  // ALL PALS VARIANT ---------------->>
    {
       id: '3993767000000047799',
      order_item_id: 19984,
      Title: 'Pediatric Advanced Life Support (PALS) (Best Value)',
      Price: '50',
      Course_id:9238,
      Validity_In_Year:2,
      CME_Available:true,
      Full_Size_PDF_Certificate:true,
      name: 'PALS',
    },
    {
       id: '3993767000000047799',
      order_item_id: 1042,
      Title: 'PALS Bundle',
      Price: '45',
      Course_id:9238,
      Validity_In_Year:1,
      CME_Available:false,
      Full_Size_PDF_Certificate:true,
      name: 'PALS',
    },
    {
       id: '3993767000000047799',
      order_item_id: 19985,
      Title: 'Pediatric Advanced Life Support (PALS) (Basic)',
      Price: '30',
      Course_id:9238,
      Validity_In_Year:0.5,
      CME_Available:false,
      Full_Size_PDF_Certificate:false,
      name: 'PALS',
    },
    {
      // id: '3993767000000047799',
     order_item_id: 5241,
     Title: 'Full Access',
     Price: '150',
  //    Course_id:9238,
     Validity_In_Year:2,
     CME_Available:true,
      Full_Size_PDF_Certificate:true,
      name: 'Full Access',
   },
]

export const CertificateVariantsAddons= 
[
  {
      id: [25320, 25324],
      details: [
          { 
              addon_id:25320,
              type: 'basic',
          },
          {
              addon_id:25324,
              type: 'standard',
          }
      ],
      Course: 'ACLS',
      Course_id: 4526,
      order_item_id: 19986
  },
  {
      id: [25318, 25323],
      details: [
        { 
          addon_id:25318,
          type: 'basic',
      },
      {
          addon_id:25323,
          type: 'standard',
      }
      ],
      Course: 'BLS',
      Course_id: 9985,
      order_item_id: 19981
  },
  {
      id: [25322, 25325],
      details: [
        { 
          addon_id:25322,
          type: 'basic',
      },
      {
          addon_id:25325,
          type: 'standard',
      }
      ],
      Course: 'PALS',
      Course_id: 9238,
      order_item_id: 19984
  }
]
// DEV ADDON IDs--->>>
// [
//   {
//       id: [20725, 20727],
//       details: [
//           {
//               addon_id:20725,
//               type: 'basic',
//           },
//           {
//               addon_id:20727,
//               type: 'standard',
//           }
//       ],
//       Course: 'ACLS',
//       Course_id: 4526,
//       order_item_id: 19986
//   },
//   {
//       id: [20723, 20724],
//       details: [
//         {
//           addon_id:20723,
//           type: 'basic',
//       },
//       {
//           addon_id:20724,
//           type: 'standard',
//       }
//       ],
//       Course: 'BLS',
//       Course_id: 9985,
//       order_item_id: 19981
//   },
//   {
//       id: [20728, 20729],
//       details: [
//         {
//           addon_id:20728,
//           type: 'basic',
//       },
//       {
//           addon_id:20729,
//           type: 'standard',
//       }
//       ],
//       Course: 'PALS',
//       Course_id: 9238,
//       order_item_id: 19984
//   }
// ]

export const CME_LicenseType=[
  {
    value:'Full',
    label:'Full License'
  },
  {
    value:'Administrative',
    label:'Admin License'
  },  {
    value:'Temporary',
    label:'Temporary Faculty'
  },
]

export const CME_RequestType=[
  {
    value:'initial',
    label:'Initial'
  },
  {
    value:'renewal',
    label:'Renewal'
  },  {
    value:'lapsed',
    label:'Lapsed'
  },
]

export const courseLanguages=[
  {
    name: 'English',
    abbr: 'ENG',
    id: '3993767000000700001'
  },
  {
    name: 'Hindi',
    abbr: 'HI',
    id: '3993767000000700002'
   },
  {
    name: 'Spanish',
    abbr: 'ES',
    id: '3993767000000700003'
  },
  {
    name: 'Arabic',
    abbr: 'AR',
    id: '3993767000000700004'
  }
]
export const Partners=[ "teamhealth.com" ];



export const  ProfessionWithCertificates = [
  {
      name: "Doctor", designation_id: "3993767000000111003", 
      license: "License-Physician", certificate_id: "3993767000000019870"
  },
  {
      name: "Physician Assistant", designation_id: "3993767000000111015", 
      license: "License-Physician Assistant", certificate_id: "3993767000000020871"
  },        
  {
      name: "Nurse Practitioner", designation_id: "3993767000000111019", 
      license: "License-APRN", certificate_id: "3993767000000022873"
  },        
  {
      name: "Nurse", designation_id: "3993767000000111007", 
      license: "License-RN", certificate_id: "3993767000000021872"
  },
  {
    name: "Certified Registered Nurse Anesthetist", designation_id: "3993767000000111055", 
    license: "License-CRNA", certificate_id: "3993767000000022874"
},

]


export const CMEComplianceRuleSet = [
  {
    "State": "Massachusetts",
    "State_ID": "3993767000000019003",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": '-',
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Alzheimer's Disease and Related Dementias",
        "Requirement": "1.0",
        "Note": 'REQUIRED ONLY if serving adult populations and not completed previously',
      }, {
        "Sr.No": "02",
        "Topic": "Child Abuse",
        "Requirement": "1.0",
        "Note": 'REQUIRED ONLY if not completed previously',
      }, {
        "Sr.No": "03",
        "Topic": "Domestic Violence and Sexual Violence",
        "Requirement": "1.0",
        "Note": 'REQUIRED ONLY if not completed previously',
      }, {
        "Sr.No": "04",
        "Topic": "Electronic Health Records",
        "Requirement": "3.0",
        "Note": 'REQUIRED ONLY if not completed previously',
      }, {
        "Sr.No": "05",
        "Topic": "End of Life Care",
        "Requirement": "2.0 credits which may be applied towards Risk Management",
        "Note": 'REQUIRED ONLY if not completed previously',
      }, {
        "Sr.No": "06",
        "Topic": "Opioid Education and Pain Management",
        "Requirement": "3.0 credits which may be applied towards Risk Management",
        "Note": 'REQUIRED IF PRESCRIBING',
      }, {
        "Sr.No": "07",
        "Topic": "Risk Management",
        "Requirement": "10.0",
        "Note": 'REQUIRED',
      }, {
        "Sr.No": "08",
        "Topic": "Implicit Bias Training",
        "Requirement": "2.0 credits which may be applied towards Risk Management",
        "Note": 'REQUIRED ONLY if not completed previously',
      }, {
        "Sr.No": "09",
        "Topic": "Board Regulations",
        "Requirement": "2.0 credits which may be applied towards Risk Management",
        "Note": 'REQUIRED',
      },{
        "Sr.No": "10",
        "Topic": "General",
        "Requirement": "50.0",
        "Note": '-',
      },
    ]
  },
  {
    "State": "New York",
    "State_ID": "3993767000000026021",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years in June of even numbered years",
    "Note": '-',
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Infection Control",
        "Requirement": "1.0",
        "Note": 'Must complete every 4 years',
      }, {
        "Sr.No": "02",
        "Topic": "Pain Management",
        "Requirement": "3.0",
        "Note": 'For physicians who hold a NY DEA License',
      }, {
        "Sr.No": "03",
        "Topic": "Pallitative Care",
        "Requirement": "3.0",
        "Note": 'For physicians who hold a NY DEA License',
      }, {
        "Sr.No": "04",
        "Topic": "Substance Abuse",
        "Requirement": "3.0",
        "Note": 'For physicians who hold a NY DEA License',
      },{
        "Sr.No": "05",
        "Topic": "General ",
        "Requirement": "0.0",
        "Note": 'No minimum amount of credits',
      }
    ]
  },
  {
    "State": "Alabama",
    "State_ID": "3993767000000047427",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every year",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opioid Education",
        "Requirement": "2.0",
        "Note": '2.0 CME every 2 years if licensee holds an Alabama Controlled Substance Registration (ACSC)',
      },
      {
        "Sr.No": "02",
        "Topic": "General",
        "Requirement": "25.0",
        "Note": 'All credits must be from Category 1',
      }
    ]
  },
  {
    "State": "Alaska",
    "State_ID": "3993767000000047115",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years expiring on Dec 31 of even years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opioid Education",
        "Requirement": "2.0",
        "Note": '-',
      },
      {
        "Sr.No": "02",
        "Topic": "General",
        "Requirement": "50.0",
        "Note": '-',
      }
    ]
  },
  {
    "State": "Arizona",
    "State_ID": "3993767000000047127",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years on or before licensee's birthday",
    "Note": "-",
    "Requirements": [ {
      "Sr.No": "01",
      "Topic": "Opioid Education",
      "Requirement": "3.0",
      "Note": '3.0 Credits for AZ DEA holders',
    },
    {
      "Sr.No": "02",
      "Topic": "General",
      "Requirement": "40.0",
      "Note": 'Per Cycle',
    }]
  },
  {
    "State": "Arkansas",
    "State_ID": "3993767000000047139",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every year at the end of licensee's birth month",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opioid Education",
        "Requirement": "1.0",
        "Note": '-',
      },
      {
        "Sr.No": "02",
        "Topic": "General",
        "Requirement": "20.0",
        "Note": '20.0 Credits per cycle - 10.0 Credits must be Category 1',
      }
    ]
  },
  {
    "State": "California",
    "State_ID": "3993767000000047151",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years on last day of birth month",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Pain Management & End of Life Care",
        "Requirement": "12.0",
        "Note": '12.0 total credits in pain management and end of life care divided any way',
      },{
        "Topic": "Geriatric Medicine",
        "Requirement": "1.0",
        "Note": 'California requires that general internists and family physicians who have a patient population of which over 25 percent are 65 years of age or older, are required to complete at least 20 percent of their mandatory CME in the field of geriatric medicine.',
      }, {
        "Topic": "General",
        "Requirement": "50.0",
        "Note": '50.0 Credits per Cycle',
      }
    ]
  },
  {
    "State": "Colorado",
    "State_ID": "3993767000000047163",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "General",
        "Requirement": "30.0",
        "Note": '30.0 Credits per Cycle',
      }
    ]
  },
  {
    "State": "Connecticut",
    "State_ID": "3993767000000026017",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Physicians must renew their licenses annually. Licenses expire on the last day of the physician's birth month. However, CMEs must be completed within the preceding 24 months.",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Infectious Disease",
        "Requirement": "1.0",
        "Note": '1.0 Credits every 6 years',
      },{
        "Sr.No": "02",
        "Topic": "Risk Management",
        "Requirement": "1.0",
        "Note": '1.0 Credits every 6 years',
      },{
        "Sr.No": "03",
        "Topic": "Behavioral Health",
        "Requirement": "2.0",
        "Note": '2.0 Credits every 6 years',
      },{
        "Sr.No": "04",
        "Topic": "Sexual Assault",
        "Requirement": "1.0",
        "Note": '1.0 Credits every 6 years',
      },{
        "Sr.No": "05",
        "Topic": "Domestic Violence",
        "Requirement": "1.0",
        "Note": '1.0 Credits every 6 years',
      },{
        "Sr.No": "06",
        "Topic": "Cultural Awareness ",
        "Requirement": "1.0",
        "Note": '1.0 Credits every 6 years',
      },{
        "Sr.No": "07",
        "Topic": "General ",
        "Requirement": "50.0",
        "Note": '50.0 Credits every 2 years',
      },
    ]
  },
  {
    "State": "Delaware",
    "State_ID": "3993767000000047443",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years on March 31 of odd-numbered years.",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "General ",
        "Requirement": "40.0",
        "Note": '40.0 Credits every 2 years',
      },
    ]
  },
  {
    "State": "Florida",
    "State_ID": "3993767000000047175",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Medical Errors",
        "Requirement": "2.0",
        "Note": 'Course MUST include information regarding the 5 most mis-diagnosed conditions',
      },{
        "Sr.No": "02",
        "Topic": "Domestic Violence",
        "Requirement": "2.0",
        "Note": 'Required every third biennium',
      },{
        "Sr.No": "03",
        "Topic": "Controlled Substance",
        "Requirement": "2.0",
        "Note": 'Required if you hold a FL DEA',
      },{
        "Sr.No": "04",
        "Topic": "General ",
        "Requirement": "38.0",
        "Note": '38.0 Credits',
      }
    ]
  },
  {
    "State": "Georgia",
    "State_ID": "3993767000000047187",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opioid Education",
        "Requirement": "3.0",
        "Note": '3.0 Credits for GE DEA holders',
      },{
        "Sr.No": "02",
        "Topic": "Sexual Violence",
        "Requirement": "2.0",
        "Note": '-',
      },{
        "Sr.No": "03",
        "Topic": "General ",
        "Requirement": "40.0",
        "Note": '40.0 Credits',
      }
    ]
  },
  {
    "State": "Guam",
    "State_ID": "3993767000000055401",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "-",
    "Note": "-",
    "Requirements": []
  },
  {
    "State": "Hawaii",
    "State_ID": "3993767000000047475",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years on January 31 of even numbered years.",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "General ",
        "Requirement": "40.0",
        "Note": '40.0 Credits all must be Category 1',
      }
    ]
  },
  {
    "State": "Idaho",
    "State_ID": "3993767000000047723",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "General ",
        "Requirement": "40.0",
        "Note": '-',
      }
    ]
  },
  {
    "State": "Illinois",
    "State_ID": "3993767000000047491",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 3 years on July 31",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opioid Education",
        "Requirement": "3.0",
        "Note": '-',
      },{
        "Sr.No": "02",
        "Topic": "Implicit Bias",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Sr.No": "03",
        "Topic": "Dementia",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Sr.No": "04",
        "Topic": "Sexual Violence",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Sr.No": "05",
        "Topic": "General ",
        "Requirement": "150.0",
        "Note": '150.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "Indiana",
    "State_ID": "3993767000000047507",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "The State of Indiana does NOT require any amount of CMEs to be completed for renewal of a license.",
    "Note": "-",
    "Requirements": []
  },
  {
    "State": "Iowa",
    "State_ID": "3993767000000047523",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years, For physicians born in even-numbered years, the license expires on the first day of the physician’s birth month in an even-numbered year. For physicians born in odd-numbered years, the license expires on the first day of the physician’s birth month in an odd-numbered year.",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "General ",
        "Requirement": "40.0",
        "Note": '-',
      }
    ]
  },
  {
    "State": "Kansas",
    "State_ID": "3993767000000047199",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Annually on August 1",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "General ",
        "Requirement": "50.0",
        "Note": 'Out of 50.0 Credits - 20.0 Credits must be Category 1',
      }
    ]
  },
  {
    "State": "Kentucky",
    "State_ID": "3993767000000047211",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 3 years by Dec 31",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Controlled Substance ",
        "Requirement": "4.5",
        "Note": 'For KY DEA Holders',
      },{
        "Sr.No": "02",
        "Topic": "Domestic Violence ",
        "Requirement": "3.0",
        "Note": 'Required only for primary care physicians. One time requirement.',
      },{
        "Sr.No": "03",
        "Topic": "General ",
        "Requirement": "60.0",
        "Note": '30.0 Credits must be category 1',
      }
    ]
  },
  {
    "State": "Louisiana",
    "State_ID": "3993767000000047539",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Annually",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opioid Education  ",
        "Requirement": "3.0",
        "Note": '-',
      },{
        "Sr.No": "02",
        "Topic": "General ",
        "Requirement": "20.0",
        "Note": '20.0 Credits all must be Category 1',
      }
    ]
  },
  {
    "State": "Maine",
    "State_ID": "3993767000000026029",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "The license renewal cycle is every 2 years on the last day of their birth month",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opioid Education  ",
        "Requirement": "3.0",
        "Note": '3.0 Credits every 2 years',
      },{
        "Sr.No": "02",
        "Topic": "General ",
        "Requirement": "40.0",
        "Note": '40.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "Maryland",
    "State_ID": "3993767000000047555",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years prior to the expiration date",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "General ",
        "Requirement": "50.0",
        "Note": '-',
      }
    ]
  },
  {
    "State": "Michigan",
    "State_ID": "3993767000000047571",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 3 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Medical Ethics",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Sr.No": "02",
        "Topic": "Pain Management",
        "Requirement": "3.0",
        "Note": '-',
      },{
        "Sr.No": "03",
        "Topic": "General ",
        "Requirement": "150.0",
        "Note": '75.0 Credits must be Category 1',
      }
    ]
  },
  {
    "State": "Minnesota",
    "State_ID": "3993767000000047235",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 3 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opiod Education",
        "Requirement": "3.0",
        "Note": 'For MN DEA holder',
      },{
        "Sr.No": "02",
        "Topic": "General",
        "Requirement": "75.0",
        "Note": '75.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "Mississippi",
    "State_ID": "3993767000000047247",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years on June 30",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opioid Education",
        "Requirement": "5.0",
        "Note": '5.0 Credits for MS DEA Holders',
      },{
        "Sr.No": "02",
        "Topic": "General",
        "Requirement": "40.0",
        "Note": '40.0 Credits per cycle - all must be category 1',
      }
    ]
  },
  {
    "State": "Missouri",
    "State_ID": "3993767000000047591",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "General",
        "Requirement": "50.0",
        "Note": '50.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "Montana",
    "State_ID": "3993767000000047259",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "The State of Montana does NOT require any amount of CMEs to be completed for renewal of a license.",
    "Note": "-",
    "Requirements": []
  },
  {
    "State": "Nebraska",
    "State_ID": "3993767000000047271",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Controlled Substance Prescribing",
        "Requirement": "3.0",
        "Note": '-',
      },{
        "Sr.No": "02",
        "Topic": "General",
        "Requirement": "50.0",
        "Note": '50.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "Nevada",
    "State_ID": "3993767000000047743",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 3 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Board Regulations",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Sr.No": "02",
        "Topic": "Pain Management",
        "Requirement": "5.0",
        "Note": '-',
      },{
        "Sr.No": "03",
        "Topic": "General",
        "Requirement": "75.0",
        "Note": '75.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "New Hampshire",
    "State_ID": "3993767000000020003",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years on the last day of month the license was issued",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Pain Management",
        "Requirement": "3.0",
        "Note": 'For NH DEA Holders',
      },{
        "Sr.No": "02",
        "Topic": "General",
        "Requirement": "100.0",
        "Note": '100.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "New Mexico",
    "State_ID": "3993767000000047615",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "Opioid Education",
        "Requirement": "2.0",
        "Note": '-',
      },{
        "Sr.No": "02",
        "Topic": "General",
        "Requirement": "40.0",
        "Note": '40.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "New Jersey",
    "State_ID": "3993767000000026033",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years on August 31",
    "Note": "-",
    "Requirements": [
      {
        "Sr.No": "01",
        "Topic": "End of Life Care",
        "Requirement": "2.0",
        "Note": '-',
      },{
        "Sr.No": "02",
        "Topic": "Cultural Awareness",
        "Requirement": "6.0",
        "Note": '-',
      },{
        "Sr.No": "03",
        "Topic": "General",
        "Requirement": "100.0",
        "Note": 'Out of 100.0 Credits - 40.0 Credits must be Category 1',
      }
    ]
  },
  {
    "State": "North Carolina",
    "State_ID": "3993767000000047283",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Substance Abuse",
        "Requirement": "3.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "60.0",
        "Note": '60.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "North Dakota",
    "State_ID": "3993767000000047311",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 3 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Substance Abuse",
        "Requirement": "3.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "60.0",
        "Note": '60.0 Credits per cycle',
      }
    ]
  },
  // {
  //   "State": "North Marina Islands",
  //   "State_ID": "3993767000000055404",
  //   "Profession": "Physician",
  //   "LicenseType": "Full License",
  //   "RequestType": "Renewal",
  //   "RenewalCycle": "Every 2 years",
  //   "Note": "-",
  //   "Requirements": []
  // },
  {
    "State": "Ohio",
    "State_ID": "3993767000000047095",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years from the date of issuance",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Board Regulation",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "50.0",
        "Note": '50.0 Credits - All must be Category 1',
      }
    ]
  },
  {
    "State": "Oklahoma",
    "State_ID": "3993767000000047631",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every year",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Pain Management",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "60.0",
        "Note": '60.0 Credits per Cycle',
      }
    ]
  },
  {
    "State": "Oregon",
    "State_ID": "3993767000000047327",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Pain Management",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Topic": "Suicide Prevention",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Topic": "Alzheimer's Disease",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Topic": "Cultural Competency",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "60.0",
        "Note": '60.0 Credits per Cycle',
      }
    ]
  },
  {
    "State": "Pennsylvania",
    "State_ID": "3993767000000047339",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years by Dec 31 of even numbered years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Pain Management",
        "Requirement": "2.0",
        "Note": '-',
      },{
        "Topic": "Child Abuse",
        "Requirement": "2.0",
        "Note": '-',
      },{
        "Topic": "Risk Management",
        "Requirement": "12.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "100.0",
        "Note": 'Out of 100.0 Credits - 20.0 Credits must be Category 1',
      }
    ]
  },
  {
    "State": "Puerto Rico",
    "State_ID": "3993767000000055403",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": []
  },
  {
    "State": "Rhode Island",
    "State_ID": "3993767000000047647",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years in June of even numbered years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Cultural Awareness",
        "Requirement": "4.0",
        "Note": '-',
      },{
        "Topic": "Risk Management",
        "Requirement": "4.0",
        "Note": '-',
      },{
        "Topic": "Antimicrobial Stewardship",
        "Requirement": "4.0",
        "Note": '-',
      },{
        "Topic": "Alzheimer's Disease",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "40.0",
        "Note": '40.0 Credits every 2 years',
      }
    ]
  },
  {
    "State": "South Carolina",
    "State_ID": "3993767000000047671",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Controlled Substances",
        "Requirement": "3.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "40.0",
        "Note": 'Out of 40.0 Credits - 30.0 Credits must be related to physicians specialty ',
      }
    ]
  },
  {
    "State": "South Dakota",
    "State_ID": "3993767000000047759",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "The State of South Dakota does NOT require any amount of CMEs to be completed for renewal of a license.",
    "Note": "-",
    "Requirements": []
  },
  {
    "State": "Tennessee",
    "State_ID": "3993767000000047351",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Opioid Education",
        "Requirement": "2.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "40.0",
        "Note": '-',
      }
    ]
  },
  {
    "State": "Texas",
    "State_ID": "3993767000000047363",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Medical Ethics",
        "Requirement": "2.0",
        "Note": '-',
      },{
        "Topic": "Pain Management",
        "Requirement": "2.0",
        "Note": '2.0 Credits - best practices, alternative treatment options, and multi-modal approaches to pain management.',
      },{
        "Topic": "Controlled Substance",
        "Requirement": "2.0",
        "Note": '2.0 Credits - prescribing and monitoring of controlled substances.',
      },{
        "Topic": "Human Trafficking",
        "Requirement": "1.0",
        "Note": 'As part of the 24 formal credits required, a course in human trafficking prevention must be completed',
      },{
        "Topic": "General",
        "Requirement": "48.0",
        "Note": '48.0 Credits every 2 years. 24.0 Formal Credits Category 1 or 1A hours. 24.0 informal hours',
      }
    ]
  },
  {
    "State": "Utah",
    "State_ID": "3993767000000047687",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every  2 Years on Jan 31 of even-numbered years.",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Controlled Substances",
        "Requirement": "3.5",
        "Note": '-',
      },{
        "Topic": "Suicide Prevention",
        "Requirement": "0.5",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "40.0",
        "Note": '40.0 Credits per cycle - 34.0 Credits must be Category 1',
      }
    ]
  },
  {
    "State": "US Virgin Islands",
    "State_ID": "3993767000000055402",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": []
  },
  {
    "State": "Vermont",
    "State_ID": "3993767000000026025",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Pain Management or Pallitaive Care",
        "Requirement": "1.0",
        "Note": '1.0 Credits in pain management OR Pallative Care',
      },{
        "Topic": "Opioid Education",
        "Requirement": "2.0",
        "Note": 'For VT DEA holders',
      },{
        "Topic": "General",
        "Requirement": "30.0",
        "Note": '-',
      }
    ]
  },
  {
    "State": "Virginia",
    "State_ID": "3993767000000047379",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years on last day of birth month.",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "General",
        "Requirement": "60.0",
        "Note": 'Out of 60.0 Credits - 30.0 Credits must be Category 1 every 2 years',
      }
    ]
  },
  {
    "State": "Washington",
    "State_ID": "3993767000000047391",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 4 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Suicide Prevention",
        "Requirement": "6.0",
        "Note": 'One Time Requirement',
      },{
        "Topic": "Opioid Education",
        "Requirement": "1.0",
        "Note": 'One Time Requirement',
      },{
        "Topic": "General",
        "Requirement": "200.0",
        "Note": '200.0 Credits per cycle',
      }
    ]
  },
  {
    "State": "Washington DC",
    "State_ID": "3993767000000047459",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years by Dec 31 of even-numbered years.",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Cultural Awareness",
        "Requirement": "2.0",
        "Note": '-',
      },{
        "Topic": "Public Health Needs",
        "Requirement": "5.0",
        "Note": '-',
      },{
        "Topic": "Pharmacology",
        "Requirement": "1.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "50.0",
        "Note": '50.0 Credits every 2 years',
      }
    ]
  },
  {
    "State": "West Virginia",
    "State_ID": "3993767000000047403",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years preceding the license expiration date.",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Controlled Substance",
        "Requirement": "3.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "50.0",
        "Note": 'Out of 50.0 Credits - 30.0 Credits must be in the physicians area of specialty ',
      }
    ]
  },
  {
    "State": "Wisconsin",
    "State_ID": "3993767000000047415",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 2 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "Opioid Education",
        "Requirement": "2.0",
        "Note": '-',
      },{
        "Topic": "General",
        "Requirement": "30.0",
        "Note": '30.0 Credits per cycle - All must be Category 1',
      }
    ]
  },
  {
    "State": "Wyoming",
    "State_ID": "3993767000000023401",
    "Profession": "Physician",
    "LicenseType": "Full License",
    "RequestType": "Renewal",
    "RenewalCycle": "Every 3 years",
    "Note": "-",
    "Requirements": [
      {
        "Topic": "General",
        "Requirement": "60.0",
        "Note": '-',
      }
    ]
  }
];

export const ReportTypes = {
  singleUserSingleState:'type-1-report',
  singleUserMultiState:'type-2-report',
  multiUserSingleState:'type-3-report',
  multiUserMultiState:'type-4-report',
  myCompliance:'my-compliance-reports',
  myComplianceSingleReport:'my-compliance-report',
}